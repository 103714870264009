// 
// light-mode.scss
// 


// ================================ //
// ======== Custom Variable ======= //
// ================================ //

:root {
    --#{$prefix}logo-lg-height:           22px;
    --#{$prefix}logo-sm-height:           22px;

    --#{$prefix}leftbar-width:            260px;
    --#{$prefix}leftbar-width-md:         160px;
    --#{$prefix}leftbar-width-sm:         70px;
    --#{$prefix}leftbar-condensed-height: 2000px;

    --#{$prefix}topbar-height:             70px;

    --#{$prefix}menu-item-icon-size:       1.2rem;
    --#{$prefix}menu-item-font-size:       0.9375rem;
    --#{$prefix}menu-item-padding-x:       10px;
    --#{$prefix}menu-item-padding-y:       15px;

    // Footer Height
    --#{$prefix}footer-height:             60px;

    // Card Border Width
    --#{$prefix}theme-card-border-width:   0px;
}

// ================================ //
// ====== Light Mode Variable ===== //
// ================================ //

:root {
    --#{$prefix}box-shadow:                   #{0px 0px 35px 0px rgba(154, 161, 171, 0.15)};
    --#{$prefix}card-bg-custom:               #{$white};
    --#{$prefix}dropdown-bg-custom:           #{$white};
    --#{$prefix}input-bg-custom:              #{$white};
    --#{$prefix}menu-condensed-link-bg:       #{$primary};
}

// ================================ //
// ====== Dark Mode Variable ====== //
// ================================ //

html[data-theme="dark"], [data-theme="dark"] {    

    // Prefix gray variables
    @each $color, $value in $grays-dark {
        --#{$prefix}gray-#{$color}: #{$value};
    }

    --#{$prefix}gray:                           #{$gray-dark-400};

    --#{$prefix}body-bg:                        #{$body-dark-bg};
    --#{$prefix}body-color:                     #{$body-dark-color};
    --#{$prefix}body-bg-rgb:                    #{to-rgb($body-dark-bg)};
    --#{$prefix}body-color-rgb:                 #{to-rgb($body-dark-color)};

    --#{$prefix}light:                          #{$gray-dark-300};
    --#{$prefix}dark:                           #{$gray-dark-900};
    --#{$prefix}light-rgb:                      #{to-rgb($gray-dark-300)};
    --#{$prefix}dark-rgb:                       #{to-rgb($gray-dark-900)};

    --#{$prefix}box-shadow:                    #{0px 0px 35px 0px rgba(49, 57, 66, 0.5)};

    --#{$prefix}card-bg-custom:                #{$gray-dark-200};
    --#{$prefix}dropdown-bg-custom:            #{tint-color($gray-dark-200, 2%)};
    --#{$prefix}input-bg-custom:               #{$gray-dark-100};
}


// ================================ //
// ========== Main Menu =========== //
// ================================ //

// Background Light left-sidebar
html[data-menu-color="light"] {
    --#{$prefix}menu-bg:                             #ffffff;
    --#{$prefix}menu-item-color:                       #{$gray-700};
    --#{$prefix}menu-item-hover-color:                 #{$primary};
    --#{$prefix}menu-item-active-color:                #{$primary};
    --#{$prefix}help-box-bg:                           #{$primary};
}

// Dark Left Sidebar
html[data-menu-color="dark"] {
    --#{$prefix}menu-bg:                                 #{$gray-900};
    --#{$prefix}menu-item-color:                       #8391a2;
    --#{$prefix}menu-item-hover-color:                 #bccee4;
    --#{$prefix}menu-item-active-color:                #ffffff;
    --#{$prefix}help-box-bg:                             #{rgba($white, 0.07)};
}

// Brand Menu
html[data-menu-color="brand"] {
    --#{$prefix}menu-bg:                                     #{linear-gradient(135deg, #6379c3 0%, #546ee5 60%)};
    --#{$prefix}menu-item-color:                           #cedce4;
    --#{$prefix}menu-item-hover-color:                     #ffffff;
    --#{$prefix}menu-item-active-color:                    #ffffff;
    --#{$prefix}help-box-bg:                                 #{rgba($white, 0.07)};
}

// Dark Mode Left Sidebar
html[data-theme="dark"][data-menu-color="light"],
html[data-theme="dark"][data-menu-color="dark"] {
    --#{$prefix}menu-bg:                               #3a444e;
    --#{$prefix}menu-item-color:                       #8391a2;
    --#{$prefix}menu-item-hover-color:                 #bccee4;
    --#{$prefix}menu-item-active-color:                #ffffff;
    --#{$prefix}help-box-bg:                             #{rgba($white, 0.07)};
}



// ================================ //
// ========== Topbar ============== //
// ================================ //

// Light Topbar
html[data-topbar-color="light"] {
    --#{$prefix}topbar-bg:                         #ffffff;
    --#{$prefix}topbar-item-color:                 #{$gray-700};
    --#{$prefix}topbar-item-hover-color:           #{$primary};
    --#{$prefix}topbar-search-bg:                  #{tint-color($gray-200,10%)};
    --#{$prefix}topbar-user-bg:                    #{lighten($gray-100, 1%)};
    --#{$prefix}topbar-user-border:                #eef2f7;
}


// Dark Topbar
html[data-topbar-color="dark"] {
    --#{$prefix}topbar-bg:                  #313a46;
    --#{$prefix}topbar-item-color:          #8391a2;
    --#{$prefix}topbar-item-hover-color:    #bccee4;
    --#{$prefix}topbar-search-bg:           #464f5b;
    --#{$prefix}topbar-user-bg:             #3c4655;
    --#{$prefix}topbar-user-border:         #414d5d;
}

// Brand Topbar
html[data-topbar-color="brand"] {
    --#{$prefix}topbar-bg:                         #{linear-gradient(135deg, #6379c3 0%, #546ee5 60%)};
    --#{$prefix}topbar-item-color:                 rgba(255, 255, 255, 0.7);
    --#{$prefix}topbar-item-hover-color:           #ffffff;
    --#{$prefix}topbar-search-bg:                  rgba(255, 255, 255, 0.1);
    --#{$prefix}topbar-user-bg:                    rgba(255, 255, 255, 0.1);
    --#{$prefix}topbar-user-border:                rgba(255, 255, 255, 0.15);
}

// Dark Mode Topbar
html[data-theme="dark"][data-topbar-color="light"],
html[data-theme="dark"][data-topbar-color="dark"] {
    --#{$prefix}topbar-bg:                  #3a444e;
    --#{$prefix}topbar-item-color:          #8391a2;
    --#{$prefix}topbar-item-hover-color:    #bccee4;
    --#{$prefix}topbar-search-bg:           #464f5b;
    --#{$prefix}topbar-user-bg:             #3c4655;
    --#{$prefix}topbar-user-border:         #414d5d;
}