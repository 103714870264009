//
// error.scss
//

.text-error {
    color: var(--#{$prefix}primary);
    text-shadow: rgba(var(--#{$prefix}primary-rgb), 0.3) 5px 1px, rgba(var(--#{$prefix}primary-rgb), 0.2) 10px 3px;
    font-size: 5.25rem;
    line-height: 5.625rem;
}
