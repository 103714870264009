//
// accordions.scss
//

.accordion-header {
    margin: 0;
}

.custom-accordion {
    .card {
        box-shadow: none;
    }

    .card-header {
        background-color: var(--#{$prefix}gray-100);
    }

    .card-body {
        border: var(--#{$prefix}border-width) solid var(--#{$prefix}gray-100);
    }

    .accordion-arrow {
        font-size: 1.2rem;
        position: absolute;
        right: 0;
    }

    a {
        &.collapsed {
            i.accordion-arrow {
                &:before {
                    content: "\F142";
                }
            }
        }
    }
}

.custom-accordion-title {
    position: relative;
    color: var(--#{$prefix}gray-600);

    &:hover {
        color: var(--#{$prefix}gray-700);
    }
}

.accordion {
    >.card {
        >.card-header {
            border-radius: 0;
            margin-bottom: -1px;
        }
    }
}